// Preguntas.scss

// External imports
import React from 'react';
import '../Styles/Hero.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';

// Internal imports
import { preguntas_func } from "../Constants/texts.js";

// Styles imports
import "../Styles/Hero.scss";

function Preguntas() {
  return (
    <Container fluid className="generic hero hero-container-generic" >
      <Row className="gx-0  d-flex align-items-center"> 
        <Col xs={12} md={6} className="generic-image">
          <Image className="hero-img" src="students_2.png" /> 
        </Col>
        <Col xs={12} md={6} className="generic-text p-3">
          <h2 className='text-center fw-bolder'>{preguntas_func.title}</h2>
          <p class="text-justify">{preguntas_func.description}</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Preguntas;
