// MainNavbar.jsx

import { Container, Nav, Navbar, Image } from 'react-bootstrap';
import { Link, useNavigate,useLocation } from 'react-router-dom';
import "../../Styles/Layout/MainNavbar.scss";

const MainNavbar = () => {
  useNavigate();
  const location = useLocation();

  return (
    <Navbar expand="lg p-0" className="main-navbar" fixed="top">
      <Container fluid className="container-navbar">
        <Navbar.Brand as={Link} to="/" className="navbar-brand">
          <Image
            src="/logo3.png"
            rounded
            height={50}
            className="logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="navbar-collapse">
          <Nav className="ms-auto">
            <Nav.Link
              as={Link}
              to="/"
              className={`nav-link-small right-text me-3 ${location.pathname === "/" ? "active-link" : ""}`}
            >
              Inicio
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/Nosotros"
              className={`nav-link-small right-text me-3 ${location.pathname === "/Nosotros" ? "active-link" : ""}`}
            >
              Nosotros
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/Contacto"
              className={`nav-link-small right-text me-3 ${location.pathname === "/Contacto" ? "active-link" : ""}`}
            >
              Contacto
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNavbar;

