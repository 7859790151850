// External imports
import React from 'react';
import '../Styles/Hero.scss';
import { Container, Row, Col, Image } from 'react-bootstrap';

// Internal imports
import { whiteboard_func } from "../Constants/texts.js";

// Styles imports
import "../Styles/Hero.scss";

function Whiteboard() {
  return (
    <Container fluid className="generic funcion hero-container-generic">
      <Row className="gx-0 d-flex align-items-center">
        {/* Para pantallas pequeñas, el texto se mostrará arriba y la imagen abajo */}
        <Col xs={12} md={6} className="generic-text p-3">
          <h2 className='text-center fw-bolder'>{whiteboard_func.title}</h2>
          <p class="text-justify">{whiteboard_func.description}</p>
        </Col>
        <Col xs={12} md={6} className="generic-image">
          <Image className="hero-img" src="video_foto.png"fluid />
        </Col>

      </Row>
    </Container>
  );
}

export default Whiteboard;
