// External imports
import React from 'react';
import YouTube from 'react-youtube';
import '../Styles/Hero.scss';
import { Container, Row, Col} from 'react-bootstrap';

// Internal imports
import { summary_func } from "../Constants/texts.js";

function Hero() {
  // Hacer el video responsivo
  const videoOpts = {
    width: '100%',
    height: 'auto',
  };

  return (
    <Container fluid className="generic hero hero-container-generic" >
      <Row className="gx-0 d-flex align-items-center">
        <Col xs={12} md={6} className="generic-image">
          <div className="video-container">
            <YouTube opts={videoOpts} className="hero-video" videoId="6nQN8wJ32pY" />
          </div>
        </Col>
        <Col xs={12} md={6} className="generic-text-video p-3">
          <h2 className='text-center fw-bolder'>{summary_func.title}</h2>
          <p class="text-justify">{summary_func.description}</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Hero;
