import Hero from "../Components/Hero.jsx";
import Whiteboard from "../Components/Whiteboard.jsx";
import Preguntas from "../Components/Preguntas.jsx";
import { Container, Row, Col, Image } from 'react-bootstrap';
// Styles imports
import "../Styles/Home.scss";


export function Home() {
  return (
    <div className="home">
      <Container className="landing d-flex flex-column justify-content-center align-items-center">
      <Row  className="gx-0 login-section pt-5">
        <Col xs={12} md={6} className="d-flex flex-column justify-content-center align-items-center">
          <Row className="row-logo">
            <Image src="logo2.png" />
          </Row>

        </Col>
      </Row>
      </Container>
      <Hero />
      <Whiteboard />
      <Preguntas />
    </div>
  );
}
