// Nosotros.jsx

import { Card, Image, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import fondo from '../img/fondo.jpg';

// Internal imports
import { integrantes, mision, vision } from "../Constants/nosotros.js";

// Styles imports 
import "../Styles/Nosotros/Nosotros.scss";

export function Nosotros() {
  return (
    <>
      <Container className="generic-nosotros"
      >
        <Row
          className="gx-0 generic-mision-vision"
          style={{
            backgroundImage: `url(${fondo})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            minHeight: '100vh', // Espaciado para evitar que el contenido se salga
          }}
        >
          <Row className="title-mision-vision justify-content-center">
            <Image
              src="logo1.png"
              className="logo-image"
              style={{height: '300px', width: 'auto' }}
            />
          </Row>
          <Row className="gx-5">
            <Col sm={12} md={6} className="col-description">
              <h2 style={{ fontWeight: 'bolder' }}>Misión</h2>
              <p>{mision}</p>
            </Col>
            <Col sm={12} md={6} className="col-description">
              <h2 style={{ fontWeight: 'bolder' }}>Visión</h2>
              <p>{vision}</p>
            </Col>
          </Row>
        </Row>

        <Row className="gx-0 generic-integrantes"
        >
          <Row>
            <h1 style={{ fontWeight: 'bolder', marginBottom: '20px' }}>Equipo SaberIA</h1>
            <hr
              style={{
                marginBottom: '30px',
                border: '2px solid #0e1d34' // Aplica el color y grosor del borde superior
              }}
            />
            <Row className="integrantes d-flex justify-content-center">
              {integrantes.map((item, index) => (
                <Col key={index} xs={12} md={6} lg={2} className="d-flex justify-content-center">
                  <Card className="card-integrante">
                    <Image
                      src={item.image}
                      roundedCircle
                      fluid
                    />
                    <h2 className="text-name">{item.name}</h2>
                    <h3 className="text-role">{item.role}</h3>
                    <a href={item.linkedin} target="_blank" rel="noopener noreferrer" className="linkedin-icon">
                      <FontAwesomeIcon icon={faLinkedin} size="2x" color="#FAFAFB" />
                    </a>
                  </Card>
                </Col>
              ))}
            </Row>
            <hr
              style={{
                marginTop: '10px',
                border: '2px solid #0e1d34' // Aplica el color y grosor del borde superior
              }}
            />
          </Row>
        </Row>
      </Container>
    </>
  );
}
