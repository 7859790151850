// External imports
import { Container, Row, Col, Image } from "react-bootstrap";

// Internal imports
import { contacts } from "../../Constants/contacts.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

// Styles imports
import "../../Styles/Layout/Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <Container fluid className="content">
        <Row className="footer p-4" style={{ backgroundColor: '#0e1d34', color: '#ffffff' }}>
          {/* Columna de Contacto */}
          <Col sm={12} md={4} className="footer-section">
            <h5 className="fw-bolder">Contacto</h5>
            <p><FontAwesomeIcon icon={faMapMarkerAlt} /> Av. Vicuña Mackenna 3939, 8940897 San Joaquín</p>
            <p><FontAwesomeIcon icon={faEnvelope} /> SaberIACorp@gmail.com</p>
          </Col>

          {/* Columna de Redes Sociales */}
          <Col sm={12} md={4} className="footer-section">
            <h5 className="fw-bolder">Redes Sociales</h5>
            <p>Síguenos en nuestras redes sociales:</p>
            <div className="stack-contacts text-center">
              <div className="d-flex justify-content-center align-items-center">
                {contacts.map((contact, index) => (
                  <a
                    key={index}
                    href={contact.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mx-2" /* Clase de Bootstrap para espaciado horizontal */
                    style={{ textDecoration: 'none', color: '#FAFAFB' }}
                  >
                    <FontAwesomeIcon icon={contact.logo} size="2x" />
                  </a>
                ))}
              </div>
            </div>
          </Col>

          {/* Columna de Enlaces Útiles */}
          <Col sm={12} md={4} className="footer-section">
            <h5 className="fw-bolder">Feria de Software</h5>
            <ul className="list-unstyled">
              <li><a href="https://www.feriadesoftware.cl" style={{ color: '#ffffff' }}>Página Oficial</a></li>
            </ul>
          </Col>
        </Row>
        <hr
          style={{
            marginBottom: '20px',
            border: '2px solid #fff'
          }}
        />
        <Row>
          <Col>
            <Image
              src="/logo3.png"
              width={220}
              height={80}
            />
          </Col>
          <Col>
            <Image
              src="/logo1.png"
              width={120}
              height={100}
            />
          </Col>
          <Col>
            <Image
              src="/feria.png"
              width={200}
              height={80}
            />
          </Col>

        </Row>
      </Container>
    </footer>
  )
}

export default Footer;

